<template>
  <b-row>
    <b-col cols="12">
      <new-prebid-partner />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import NewPrebidPartner from './FormNewPrebidPartner.vue'

export default {
  components: {
    BRow,
    BCol,
    NewPrebidPartner,
  },
}
</script>
